import './App.css';
import { Helmet } from 'react-helmet';

function App() {


  return (
    <div className="App">
      <Helmet>
          <title>Katarina Andreassen</title>
          <meta id="og-title" property="og:title" content="Katarina Andreassen Sång, Logopedi och Osteopati" />
          <meta id="og-title" property="og:description" content="Sånglektioner, Osteopatibehandlingar med mera" />
          {/* <meta id="og-image" property="og:image" content={DefaultMetaImg} /> */}
      </Helmet>
      <section className="bg-green-50 pt-20">
        <div className="container mx-auto px-4 my-auto h-screen">
          <div className="max-w-xl mx-auto text-center">
            <a className="mb-6 inline-block text-3xl font-bold leading-none" href="#">
              <img className="h-20 rounded-full" src="/katarina.png" alt="Katarina Andreassen" width="auto"/>
            </a>
            <h2 className="mb-4 text-4xl lg:text-5xl font-bold font-heading">Katarina Andreassen</h2>
            <p className="max-w-lg mx-auto mb-6 text-gray-500 leading-loose">Osteopat, legitimerad logoped och sångpedagog i Falkenberg. </p>
            <p className="max-w-lg mx-auto mb-6 text-gray-500 leading-loose">Övnings- och behandlingsrum i Falkenbergs gamla varmbadhus, Nygatan 46. Telefonnummer <a className="text-green-700" href="tel:0706576454">0706-576454</a> </p>
            {/* <p className="max-w-lg mx-auto mb-6 text-red-600 font-bold leading-loose">
              Jag har ledigt från december 2023 till februari 2024. Välkommen att boka tider från mars 2024 och framåt.
            </p> */}
            <a className="mb-5 w-full md:w-3/4 inline-block py-2 px-6 bg-green-400 hover:bg-green-700 text-white font-bold leading-loose rounded-full transition duration-200" 
               href="https://koalendar.com/e/sanglektion">
              Boka Sånglektion
            </a>
            <a className="w-full md:w-3/4 inline-block py-2 px-6 bg-green-600 hover:bg-green-700 text-white font-bold leading-loose rounded-full transition duration-200" 
               href="https://koalendar.com/e/osteopatbehandling">
              Boka Osteopatibehandling
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
